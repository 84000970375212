<template>
  <div class="container container-cart">
    <div class="row align-items-center cart-title-row">
      <div class="col-10 title-header">
        <h2 class="page-title">
          {{$t('Cart.BASKET')}}
        </h2>
      </div>
    </div>

    
            <div class="row row-cart">
                <div class="col-12 col-lg-8 prdct-cart-header">
                    <div class="border-box-new">
                        <ul class="cart-list">
                          <template v-for="(cartItem, index)  in cartItems" >
                            <basket-item :product="cartItem" v-bind:key="index"></basket-item>
                          </template>
                          <li class="cart-total" v-if="cartItems.length > 0">
                                <div class="cart-prd-info-wrapper">
                                    <div class="cart-left-content">
                                        <h4 class="cart-total-price">
                                            {{$t('Cart.TOTAL')}}
                                        </h4>
                                    </div>
                                    <div class="cart-right-content">
                                        <h4 class="cart-total-price text-right">
                                            {{cartTotal | tenzerCurrency }}
                                        </h4>
                                    </div>
                                </div>
                            </li>

                        </ul>
                        <router-link tag="a" :to="shopNowLink" class="cart-more-btn" >
                          {{$t('Cart.ADD_MORE')}}
                        </router-link>
                    </div>
                </div>
                <checkout-form></checkout-form>
                <router-link
                  v-if="backButton == 'true'"
                  class="btn btn-theme back-route-path-btn"
                  :to="shopNowLink"
                ></router-link>
            </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
import CheckoutForm from '../components/checkout/CheckoutForm.vue';

  const BasketItem = () => import('@/components/cart/BasketItem')

  export default {
    name: 'ShoppingCart',

    components: {
      BasketItem,
      CheckoutForm
    },

    computed: {
      ...mapGetters({ cartItems: 'getCartItems', cartItemCounts: 'getCartItemCount', cartTotal: 'getCartTotal', sandwichConfiguration: 'getSandwichConfiguration', backButton: 'getBackButton'}),
      shopNowLink(){
        let routePath = '/products/';
        if( localStorage && localStorage.getItem('product_type') ){
          
          routePath += localStorage.getItem('product_type');
        }else{

          routePath += 'sandwich';
        }
        return routePath;
      },
    },

    methods: {
      convertPrice ( price ) {
        price = price.replace(',', '.');
        return price;
      },
      getProductTotal ( product ){
        
        let price = product.price.replace( '€', '').trim();

        if( product.quantity && product.quantity > 0 ){
          price = ( product.quantity * this.convertPrice(price) );
          price = parseFloat( price ) .toFixed(2).toString();
        }
        return  Number(price);
      },
      backToList ( e ){
        e.preventDefault();

      }
    },
    
    created () {
      if (!this.cartItemCounts) {
        window.location.href = this.shopNowLink;
      }
    }

  }
</script>
